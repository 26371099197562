<script>
import i18n from "../i18n";
const userController = require('@/controllers/endpoint/user')
export default {
  data() {
    return {
      user:{
        name:'--'
      },
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French"
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish"
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese"
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic"
        }
      ],
      current_language: "en"
    };
  },
    created() {
    this.userData()
    },
  methods: {
    userData(){
        const dataUser = userController.dataUser();
        dataUser.then((response) => {   //Succesfully result
        this.user = response.data[0];
        this.user.name = this.user.name[0]
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    }
  }
};
</script>

<template>
  <header id="page-topbar" style="box-shadow:none;">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="index.html" class="logo logo-dark">
            <span class="logo-sm">
              <img src="https://petsandlove.mx/membresia/wp-content/uploads/2024/01/LOGO-MEMBRESIA-2024-BLANCO.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="https://petsandlove.mx/membresia/wp-content/uploads/2024/01/LOGO-MEMBRESIA-2024-BLANCO.png" alt height="20" />
            </span>
          </a>

          <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
              <img src="https://petsandlove.mx/membresia/wp-content/uploads/2024/01/LOGO-MEMBRESIA-2024-BLANCO.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="https://petsandlove.mx/membresia/wp-content/uploads/2024/01/LOGO-MEMBRESIA-2024-BLANCO.png" alt height="50" />
            </span>
          </a>
        </div>

        <!-- App Search-->
           
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ml-2">
 
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ..." />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="ri-search-line"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div style="padding-right:40px" class="dropdown d-none d-lg-inline-block ml-1" >
          <button  
            type="button"
            class="btn header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i style="color:#011c27" class="ri-fullscreen-line"></i>
          </button>
              <span class="rounded-circle" style="color:white; background-color:#011c27; padding-left: 15px; padding-right: 15px; padding-top: 10px; padding-bottom: 10px;">{{user.name}}</span>
        </div>    
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}
</style>