export const menuItems = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'Categorias',
        icon: 'ri-group-line',
        link: '/categorias'
    },
    {
        id: 3,
        label: 'Subcategorias',
        icon: 'ri-search-eye-line',
        link: '/subcategorias'
    },
    {
        id: 4,
        label: 'Empresas',
        icon: 'ri-stack-line',
        link: '/empresas'
    },
    {
        id: 4,
        label: 'Descuentos',
        icon: 'ri-stack-line',
        link: '/descuentos'
    },
    {
        id: 5,
        label: 'Admin',
        icon: 'ri-equalizer-line',
        link: '/admin'
    }
]